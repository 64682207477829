import axios from "@node/axios";
import { Notification } from "@node/element-ui";
import i18n from "@o-din/src/i18n.js";

import adapter from "@o-din/src/adapter";

let session_adapter = axios.create({
  baseURL: "/",
  headers: {
    "Accept": "application/json",
    "Content-type": "application/json"
  }
})

let errorInterceptor = function (error) {
  const statusCode = error.response ? error.response.status : undefined;
  console.log(`-> not_stonks: ${statusCode}`);
  if (!statusCode) {
    Notification.error({ title: i18n.t('errors.net_err'), message: i18n.t('errors.net_err_message') });
  } else {
    switch (statusCode) {
      case 401:
        break;
      case 402:
        id = JSON.parse(localStorage.current_user).facility.id
        window.location = `facilities/${id}`;
        break;
      case 404:
        window.location = '/pageNotFound';
        break;
      case 422:
        console.log("meh");
        break;
      case 500:
        Notification.error({ title: i18n.t('errors.server_error'), message: i18n.t('errors.server_err_message') });
        break;
      case 502:
        Notification.warning({ title: i18n.t('errors.server_err_res'), message: i18n.t('errors.net_err_message') });
        break;
      default:
        Notification.error({ title: i18n.t('errors.unknown_err'), message: error });
        break;

    }
  }

  return Promise.reject(error.response.data);
}

session_adapter.interceptors.response.use((r) => r, errorInterceptor);

export default {
  passwords: {
    reset: function(params) {
      return adapter.post("/api/v4/users/reset_password", params)
    },
    update: function(params) {
      return adapter.patch("/api/v4/users/update_password", params)
    },
  },
  registration: {
    show: () => {
      return adapter.get("/api/v4/registration.json")
    }
  },
  registrations: {
    create: function(params = {}) {
      return adapter.post("sign_up.json", params)
    }
  },
  sessions: {
    create: function(params = {}) {
      return session_adapter.post("/api/users/sign_in.json", params)
    },
    destroy: function() {
      return adapter.delete("/api/users/sign_out.json")
    }
  },
  action_reports: {
    index: function(params = {}) {
      return adapter.get('api/v4/action_reports.json', { params: params })
    }
  },
  locales: {
    index: function(params = {}) {
      return adapter.get('api/v4/locales.json', { params: params })
    }
  },
  components: {
    index: function (params = {}) {
      return adapter.get("api/v4/components.json", { params: params })
    }
  },
  roles: {
    index: function (params = {}) {
      return adapter.get("api/v4/roles.json", { params: params })
    }
  },
  feedbacks: {
    create: function(params = {}) {
      return adapter.post("api/v4/feedbacks.json", params)
    },
    index: function(params = {}) {
      return adapter.get("api/v4/feedbacks.json", { params: params })
    }
  },
  imports: {
    index: function(params = {}) {
      return adapter.get("api/v4/imports.json", { params: params })
    },
    show: (id, params = {}) => {
      return adapter.get(`api/v4/imports/${id}.json`)
    },
    create: (params) => {
      return adapter.post("api/v4/imports.json", params)
    },
  },
  issues_exports: {
    create: function(params = {}) {
      return adapter.post("api/v4/issues_exports.json", params)
    },
    index: function(params = {}) {
      return adapter.get("api/v4/issues_exports.json", { params: params })
    }
  },
  executors: {
    index: function(params = {}) {
      return adapter.get("api/v4/executors.json", { params: params })
    },
    chart_executors: function(params = {}) {
      return adapter.get("api/v4/executors/chart_executors.json", { params: params })
    },
    chief_engineers: function (params = {}) {
      return adapter.get("api/v4/executors/chief_engineer_collection.json", { params: params})
    }
  },
  nfc_tags: {
    index: function (params = {}) {
      return adapter.get('api/v4/nfc_tags.json', { params: params })
    },
    show: function(id) {
      return adapter.get(`/api/v4/nfc_tags/${id}.json`)
    },
    update: function(id, params = {}) {
      return adapter.patch(`api/v4/nfc_tags/${id}.json`, params)
    }
  },
  notification_rules: {
    index: function (params = {}) {
      return adapter.get('api/v4/notification_rules.json', {params: params})
    },
    create: function(params = {}) {
      return adapter.post("api/v4/notification_rules.json", params )
    },
    update: function(id, params = {}) {
      return adapter.patch(`api/v4/notification_rules/${id}.json`, params)
    },
    apply_categories: function(params = {}) {
      return adapter.post("api/v4/notification_rules/apply_categories.json", params)
    }
  },
  facilities: {
    index: function(params = {}) {
      return adapter.get("/api/v4/facilities.json", { params: params })
    },
    full_index: function(params = {}) {
      return adapter.get("/api/v4/facilities/full_index.json", { params: params })
    },
    update: function(id, params) {
      return adapter.patch(`/api/v4/facilities/${id}.json`, params)
    },
    create: function(params) {
      return adapter.post('api/v4/facilities.json', params)
    },
    show: function(id) {
      return adapter.get(`/api/v4/facilities/${id}.json`)
    },
    deep_clone: function(id, params) {
      return adapter.post(`/api/v4/facilities/${id}/deep_clone.json`, params)
    }
  },
  user_facilities: {
    create: function(params) {
      return adapter.post('api/v4/user_facilities.json', params)
    },
    update: function(id, params) {
      return adapter.patch(`/api/v4/user_facilities/${id}.json`, params)
    },
    delete: function(id) {
      return adapter.delete(`/api/v4/user_facilities/${id}.json`)
    },
  },
  blacklists: {
    index: function(params = {}) {
      return adapter.get("/api/v4/blacklists.json", { params: params })
    },
    update: function(id, params) {
      return adapter.patch(`/api/v4/blacklists/${id}.json`, params)
    },
    create: function(params) {
      return adapter.post('api/v4/blacklists.json', params)
    },
    show: function(id) {
      return adapter.get(`/api/v4/blacklists/${id}.json`)
    }
  },
  buildings: {
    index: function(params = {}) {
      return adapter.get("/api/v4/buildings.json", { params: params })
    },
    full_index: function(params = {}) {
      return adapter.get("/api/v4/buildings/full_index.json", { params: params })
    },
    show: function(id) {
      return adapter.get(`/api/v4/buildings/${id}.json`)
    },
    update: function(id, params) {
      return adapter.patch(`/api/v4/buildings/${id}.json`, params)
    },
    create: function(params) {
      return adapter.post("api/v4/buildings.json", params)
    }
  },
  floors: {
    index: function(params = {}) {
      return adapter.get("api/v4/floors.json", { params: params })
    },
    show: function(id) {
      return adapter.get(`api/v4/floors/${id}.json`)
    },
    update: function(id, params) {
      return adapter.patch(`api/v4/floors/${id}.json`, params, { headers: { "Content-Type": "multipart/form-data" }} )
    },
    create: function(params) {
      return adapter.post("api/v4/floors.json", params)
    }
  },
  rooms: {
    index: function(params = {}) {
      return adapter.get("api/v4/rooms.json", { params: params })
    },
    show: function(id) {
      return adapter.get(`api/v4/rooms/${id}.json`)
    },
    create: function(params) {
      return adapter.post("api/v4/rooms.json", params, { headers: { "Content-Type": "multipart/form-data" }})
    },
    update: function(id, params) {
      return adapter.patch(`api/v4/rooms/${id}.json`, params)
    },
    documents: {
      index: function(params = {}) {
        return adapter.get("api/v4/room/documents.json", { params: params })
      }
    }
  },
  room_types: {
    index: function(params = {}) {
      return adapter.get("api/v4/room_types.json", { params: params })
    },
    show: function(id) {
      return adapter.get(`api/v4/room_types/${id}.json`)
    },
    create: function(params) {
      return adapter.post("api/v4/room_types.json", params)
    },
    update: function(id, params) {
      return adapter.patch(`api/v4/room_types/${id}`, { room_type: params } )
    }
  },
  companies: {
    index: function(params = {}) {
      return adapter.get("api/v4/companies.json", { params: params })
    },
    update: function(id, params) {
      return adapter.patch(`api/v4/companies/${id}.json`, params)
    },
    show: function(id) {
      return adapter.get(`api/v4/companies/${id}.json`)
    },
    stats: function(id) {
      return adapter.get(`api/v4/companies/${id}/stats.json`)
    },
    create: function(params) {
      return adapter.post("api/v4/companies.json", params)
    }
  },
  users: {
    index: function(params = {}) {
      return adapter.get("api/v4/users.json", { params: params })
    },
    fast_index: function(params = {}) {
      return adapter.get("api/v4/users/fast_index.json", { params: params })
    },
    update: function(id, params = {}) {
      return adapter.patch(`api/v4/users/${id}.json`, params )
    },
    create: function(params) {
      return adapter.post("api/v4/users.json", params)
    },
    me: function(params = {}) {
      return adapter.get('api/v4/users/profile.json', { params: params })
    },
    get: function(id) {
      return adapter.get(`api/v4/users/${id}.json`)
    },
    show: function(id) {
      return adapter.get(`api/v4/users/${id}.json`)
    },
    resend_unlock: function(params) {
      return adapter.post("api/v4/users/resend_unlock.json", params)
    },
    update_unlock: function(params) {
      return adapter.patch("api/v4/users/update_unlock.json", params)
    },
    delete: function(id) {
      return adapter.delete(`api/v4/users/${id}.json`)
    },
    export: function(params = {}) {
      return adapter.get('api/v4/users.xlsx', { params: params, responseType: 'blob' })
    },
  },
  info: {
    index: function(params = {}) {
      return adapter.get("api/v4/info.json", { params: params })
    }
  },
  // Maintence Issues
  maintenance_issues: {
    index: function(params = {}) {
      return adapter.get("api/v4/maintenance_issues.json", { params: params })
    },
    show: (id) => {
      return adapter.get(`api/v4/maintenance_issues/${id}.json`)
    },
    create: (params) => {
      return adapter.post("api/v4/maintenance_issues.json?src=web", params)
    },
    archive: function(params = {}) {
      return adapter.get("api/v4/maintenance_issues/archive.json", { params: params })
    },
    update: function(id, params = {}) {
      return adapter.patch(`api/v4/maintenance_issues/${id}.json?src=web`, params)
    },
    executors: function(id) {
      return adapter.get(`api/v4/maintenance_issues/${id}/executors_collection.json`)
    },
    destroy: function(id) {
      return adapter.delete(`api/v4/maintenance_issues/${id}.json`)
    },
  },
  statuses: {
    index: function(params = {}) {
      return adapter.get("api/v4/statuses.json", { params: params })
    }
  },
  services: {
    index: function(params = {}) {
      return adapter.get("api/v4/services.json", { params: params })
    },
    all_services: function(params = {}) {
      return adapter.get("api/v4/all_services.json", { params: params })
    },
    get: function(id) {
      return adapter.get(`api/v4/services/${id}.json`)
    },
    create: function(params = {}) {
      return adapter.post("api/v4/services.json", params )
    },
    update: function(id, params = {}) {
      return adapter.patch(`api/v4/services/${id}.json`, params)
    },
    destroy: function(id, params = {}) {
      return adapter.delete(`api/v4/services/${id}.json`)
    }
  },
  work_categories: {
    index: function(params = {}) {
      return adapter.get("api/v4/work_categories.json", { params: params })
    },
    all_work_categories: function(params = {}) {
      return adapter.get('api/v4/all_work_categories.json', { params: params })
    },
    create: function(params = {}) {
      return adapter.post("api/v4/work_categories.json", params)
    },
    update: function(id, params = {}) {
      return adapter.patch(`api/v4/work_categories/${id}.json`, params)
    },
    show: function(id) {
      return adapter.get(`api/v4/work_categories/${id}.json`)
    }
  },
  state_for_work_categories: {
    index: function(params = {}) {
      return adapter.get("/api/v4/work_categories/state_for_work_categories.json", { params: params })
    }
  },
  dashboards: {
    index: function(params = {}) {
      return adapter.get('/api/v4/dashboards.json')
    },
    update: function(id, params = {}) {
      return adapter.patch(`/api/v4/dashboards/${id}.json`)
    }
  },
  // O1 specifics
  guests_sessions: {
    create_multiple: function(params) {
      return adapter.post('/api/v4/guest_sessions.json', params)
    }
  },
  pay_executors: {
    index: function(params = {}) {
      return adapter.get('/api/v4/users.json', { params: { filters: { role_in: ['chief_engineer', 'director'] } } })
    }
  },
  removal: {
    delete_by_ids: function(params = {}) {
      return adapter.get('/api/v4/removal/delete_by_ids', { params: params })
    }
  }
}
